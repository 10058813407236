<template>
    <section>
        <div v-if="mostrarTitulo">
          <div class="d-flex justify-space-between align-center text-sm-left px-4">
            <span class="text-h6 text-uppercase font-weight-bold secondary--text mx-1 mx-sm-8 mx-md-0">
              <NavButton />
              Solicitud de gestión de usuarios
            </span>
            <v-btn 
              v-if="formInfoGeneral?.tipoSolicitud?.id == 2 && stepper != 1" 
              class="mb-4" 
              color="secondary"
              @click.stop="modalHistorialCambiosAbierta = true"
            >
              <v-icon class="mr-1" color="white">mdi-history</v-icon>
              Historial de cambios
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <v-stepper v-model="stepper" elevation="0" :class="stepperClass">
          <v-stepper-items>
            <v-stepper-content step="1" class="px-0 py-0">
              <div class="mx-1 my-1">
                <v-form @submit.prevent="validStep1()">
                  <FormInformacionGeneralComponent 
                    ref="form1" 
                    :modo="modo"
                    @on-verificacion-usuario="manejarCambioVerificacion"
                  />
                  <v-row justify="center" class="mt-4">
                    <v-col cols="12" lg="6">
                      <div class="d-flex align-center flex-wrap-reverse justify-center" style="gap: 16px;">
                        <v-btn 
                          color="primary" 
                          class="flex-grow-1 flex-shrink-1"
                          outlined
                          x-large
                          @click="$router.back()"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn 
                          color="primary" 
                          type="submit"
                          class="flex-grow-1 flex-shrink-1"
                          x-large
                          :disabled="!usuarioVerificado"
                        >
                          Continuar
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="px-0 py-0">
                <div class="mx-1 my-1">
                  <v-form @submit.prevent="validStep2()">
                    <InformacionGeneralComponent class="mb-4" />
                    <FormMainCuentaComponent ref="mainCuenta" :perfil-rol-pre-seleccionado="perfilRolPreSeleccionado" />
                    <v-row justify="center" dense no-gutters>
                      <v-col cols="12" lg="6">
                        <div class="d-flex align-center mt-4 justify-center" style="gap: 16px;">
                          <v-btn 
                            color="primary"
                            class="flex-grow-1 flex-shrink-1"
                            style="min-width: 200px;"
                            outlined
                            x-large
                            @click="stepper = 1"
                          >
                            Regresar
                          </v-btn>
                          <v-btn 
                            color="primary"
                            class="flex-grow-1 flex-shrink-1"
                            style="min-width: 200px;"
                            :disabled="$refs?.mainCuenta?.btnSolicitar"
                            x-large
                            type="submit"
                          >
                            Solicitar
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-dialog v-if="formInfoGeneral?.tipoSolicitud?.id == 2 && stepper != 1" v-model="modalHistorialCambiosAbierta" max-width="1000px" persistent>
          <v-card style="position: relative;">
              <v-card-title class="px-0 py-0 flex-column" style="position: sticky; top: 0; z-index: 2; background: #fff;">
                <div class="d-flex px-4 py-4" style="width: 100%">
                  <v-btn 
                      style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                      class="pt-0"
                      @click.stop="modalHistorialCambiosAbierta = false" 
                      text
                      rounded
                  >
                      <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </div>
                <v-divider style="width: 100%"  />
              </v-card-title>
              <v-card-text class="px-4 pt-4 pb-0">
                <div class="px-1 py-1">
                  <ModalHistoriaCambiosComponent :idUsuario="idUsuario" />
                </div>
                <div 
                  class="d-flex justify-center align-center py-4" 
                  style="position: sticky; bottom: 0px; gap: 16px; z-index: 1; background: #FFF;"
                >
                  <v-btn 
                    class="flex-grow-1 flex-shrink-1" 
                    color="primary" 
                    large
                    @click.stop="modalHistorialCambiosAbierta = false"
                  >
                    Cerrar
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationDialogComponent
            :show="modalConfirmar"
            btnConfirmar="Aceptar"
            :title="validateTitle"
            message="De confirmarse la siguiente acción, los cambios no serán reversibles."
            @close="modalConfirmar = false"
            @confirm="accionCuenta"
        />
    </section>
</template>
<script>
import FormInformacionGeneralComponent from "./FormInformacionGeneralComponent.vue";
import InformacionGeneralComponent from "./InformacionGeneralComponent.vue";
import FormMainCuentaComponent from "./FormMainCuentaComponent.vue";
import ModalHistoriaCambiosComponent from "./ModalHistoriaCambiosComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import NavButton from "@/components/utils/NavButton.vue";

export default {
  name: "CrearSolicitudUsuario",
  emits: ['on-title-load'],
  components: {
    FormInformacionGeneralComponent,
    InformacionGeneralComponent,
    ModalHistoriaCambiosComponent,
    FormMainCuentaComponent,
    ConfirmationDialogComponent,
    NavButton
  },
  props: {
    mostrarTitulo: { type: Boolean, default: true },
    modo: { type: String },
    perfilRolPreSeleccionado: { type: [Number, String] },
    stepperClass: { type: String }
  },
  data: () => ({
    stepper: 1,
    modalConfirmar: false,
    usuarioVerificado: false,
    modalHistorialCambiosAbierta: false,
  }),
  computed: {
    ...mapState("solicitudCuenta", [
      "formInfoGeneral",
      "formMainCuenta",
      "idUsuario",
    ]),
    ...mapGetters("solicitudCuenta", ["$v"]),
    validateTitle() {
      if (this.formInfoGeneral?.tipoSolicitud?.id == 1) {
        return "¿Desea enviar la solicitud a aprobación?";
      } else {
        return "¿Desea enviar la solicitud a modificación?";
      }
    },
  },
  methods: {
    ...mapMutations("solicitudCuenta", [
      "resetState",
      "cleanUnidadesUsuario",
      "setFormMainCuenta",
      "setFormInfoGeneral",
    ]),
    ...mapActions("solicitudCuenta", ["$touch"]),
    // Funcion para obtener url de documento
    async obtainUrlDocumento() {
      try {
        let formData = new FormData();
        formData.append(
          "documento_institucional",
          this.formMainCuenta?.tituloUsuario
        );
        const response = await this.services.Usuarios.postDocumento(formData);
        if (response) {
          return response?.data?.url_documento_cargo;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Funcion para validar el primer formulario
    async validStep1() {
      if (this.$refs.form1.$v.$invalid) {
        this.$refs.form1.$v.$touch();
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe completar todos los campos requeridos",
        });
      } else {
        if (this.$refs.form1.dataVerifyUser) {
          this.$refs.form1.saveData();
          if (
            this.formInfoGeneral?.tipoSolicitud?.id == 1 ||
            this.formInfoGeneral?.tipoSolicitud?.id == 2
          ) {
            this.stepper++;
          }
        }
      }
    },
    // Funcion para validar el segundo formulario
    validStep2() {
      if (this.$v.$invalid) {
        this.$touch();
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe completar todos los campos requeridos",
        });
      } else {
        this.modalConfirmar = true;
      }
    },
    // Funcion para enviar la solicitud
    async accionCuenta() {

      // ->
      if (
        this.formMainCuenta?.stckUnidades.length == 0 &&
        this.formMainCuenta?.divisionUsuario != null
      ) {
        this.$store.commit(
          "solicitudCuenta/addUnidadUsuario",
          this.formMainCuenta?.divisionUsuario
        );
      } else if (
        this.formMainCuenta?.stckUnidades.length == 1 &&
        this.formMainCuenta?.divisionUsuario != null &&
        this.formMainCuenta?.rolPerfilUsuario.id !== 7
      ) {
        if (
          this.formMainCuenta?.stckUnidades[0].id !=
          this.formMainCuenta?.divisionUsuario.id
        ) {
          this.cleanUnidadesUsuario();
          this.$store.commit(
            "solicitudCuenta/addUnidadUsuario",
            this.formMainCuenta?.divisionUsuario
          );
        }
      }
      // <-
      let urlDocumento = null;

      if (this.formMainCuenta?.tituloUsuario != null) {
        // PETICION PARA RETORNAR URL DE ARCHIVO
        urlDocumento = await this.obtainUrlDocumento();
        if (urlDocumento == null) {

          this.temporalAlert({
            show: true,
            type: "error",
            message:
              "Error al subir el documento, intente nuevamente. Si el error persiste contacte al administrador",
          });
          return;
        }
      }

      if (this.formInfoGeneral?.tipoSolicitud?.id == 1) {
        // PETICION A BACK END DE CREACION DE USUARIO
        try {
          let params = {
            id_tipo_solicitud: this.formInfoGeneral?.tipoSolicitud?.id,
            nacionalidad: this.formInfoGeneral?.nacionalidad?.id,
            numero_documento: this.formInfoGeneral?.numeroDocumento,
            primer_nombre: this.formInfoGeneral?.primerNombre,
            segundo_nombre: this.formInfoGeneral?.segundoNombre
              ? this.formInfoGeneral?.segundoNombre
              : undefined,
            primer_apellido: this.formInfoGeneral?.primerApellido,
            segundo_apellido: this.formInfoGeneral?.segundoApellido
              ? this.formInfoGeneral?.segundoApellido
              : undefined,
            apellido_casada: this.formInfoGeneral?.apellidoCasada
              ? this.formInfoGeneral?.apellidoCasada
              : undefined,
            // ->
            correo_electronico: this.formMainCuenta?.emailUsuario,
            telefono: this.formMainCuenta?.telefonoUsuario,
            profesion: this.formMainCuenta?.profesionUsuario?.id,
            rol_perfil: this.formMainCuenta?.rolPerfilUsuario?.id,
            fecha_caducidad: this.moment(
              this.formMainCuenta?.fechaCaducidadUsuario,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD HH:mm:ss"),
            cargo_funcional: this.formMainCuenta?.cargoUsuario,
            informacion_adicional: this.formMainCuenta?.infoAdicionalUsuario
              ? this.formMainCuenta?.infoAdicionalUsuario
              : undefined,
            unidades: this.formMainCuenta?.stckUnidades,
            url_documento_institucional: urlDocumento,
          };
          const response = await this.services.Usuarios.postSolicitudUsuario(
            params
          );
          if (response) {
            this.$router.push({ name: "listado-usuarios-solicitudes" });
          }
        } catch (error) {
          console.log(error);
        }
      } else if (this.formInfoGeneral?.tipoSolicitud?.id == 2) {
        // PETICION A BACK END DE MODIFICACION DE USUARIO
        try {
          let params = {
            id_tipo_solicitud: this.formInfoGeneral?.tipoSolicitud?.id,
            nacionalidad: this.formInfoGeneral?.nacionalidad?.id,
            numero_documento: this.formInfoGeneral?.numeroDocumento,
            primer_nombre: this.formInfoGeneral?.primerNombre,
            segundo_nombre: this.formInfoGeneral?.segundoNombre
              ? this.formInfoGeneral?.segundoNombre
              : undefined,
            primer_apellido: this.formInfoGeneral?.primerApellido,
            segundo_apellido: this.formInfoGeneral?.segundoApellido
              ? this.formInfoGeneral?.segundoApellido
              : undefined,
            apellido_casada: this.formInfoGeneral?.apellidoCasada
              ? this.formInfoGeneral?.apellidoCasada
              : undefined,
            // ->
            correo_electronico: this.formMainCuenta?.emailUsuario,
            telefono: this.formMainCuenta?.telefonoUsuario,
            profesion: this.formMainCuenta?.profesionUsuario?.id,
            rol_perfil: this.formMainCuenta?.rolPerfilUsuario?.id,
            fecha_caducidad: this.moment(
              this.formMainCuenta?.fechaCaducidadUsuario,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD HH:mm:ss"),
            cargo_funcional: this.formMainCuenta?.cargoUsuario,
            informacion_adicional: this.formMainCuenta?.infoAdicionalUsuario
              ? this.formMainCuenta?.infoAdicionalUsuario
              : undefined,
            unidades: this.formMainCuenta?.stckUnidades,
            url_documento_institucional: urlDocumento
              ? urlDocumento
              : this.formMainCuenta?.urlDocumentoInstitucional,
            id_usuario_modificado: this.idUsuario,
            estado_usuario: this.formInfoGeneral?.estadoUsuario,
            observaciones_modificacion:
              this.formMainCuenta?.detalleModificacionUsuario,
          };
          const response = await this.services.Usuarios.postSolicitudUsuario(
            params
          );
          if (response) {
            this.$router.push({ name: "listado-usuarios-solicitudes" });
          }
        } catch (error) {
          console.log(error);
        }
      }

    },
    manejarCambioVerificacion(verificado) {
      this.usuarioVerificado = verificado;
    }
  },
  mounted() {
    this.$emit('on-title-load', 'Solicitud de gestión de usuarios');
  },
  beforeDestroy() {
    this.resetState();
  },
};
</script>